





























































































































import {Component, Vue} from "vue-property-decorator";
import {RequestPaging} from "@/utils/util";
import LsPagination from "@/components/ls-pagination.vue";
import {
  apiDelSelectTopic,
  apiDelSingleTopic,
  apiGetAllTopic,
  apiGetTopicDetailById,
  apiGetTopicDetailByIds
} from "@/api/goods";
import {filterTableData} from "@/utils/table";
import {commonAlert} from "@/utils/confirm";

@Component({
  components: {
    LsPagination
  }
})
export default class TopicManage extends Vue {
  // tab默认选中第一个：画册模式
  activeName: string = '1'
  tableData: any = []
  pager: RequestPaging = new RequestPaging();
  // 被选中的所有数据
  multipleSelection: any = []
  statusFilters: any = [{
    text: '未上线', value: 0
  }, {
    text: '已上线', value: 1
  }, {
    text: '已下线', value: 2
  }]
  form: any = {
    keywords: '',
    order_by_field: '',
    order_val: '',
    topic_id: '',
    work_bench_type: 1,
    status: 0,
    create_time: ''
  }

  // 创建人筛选
  get filtersCreateName() {
    return filterTableData(this.tableData, 'create_name')
  }


  // 最后修改人筛选
  get filtersLastName() {
    return filterTableData(this.tableData, 'last_name')
  }

  // 新建主题
  goNewTopic (workBenchType:any) {
    console.log(workBenchType)
    this.$router.push({
      name: 'new_topic',
      params: {
        workBenchType
      }
    })
  }

  // 更改上线下线状态 0:未上线；1：上线；2：已下线
  // changeOnlineStatus(id: any, status: any) {
  //   if (status === 0 || status === 2) {
  //     status = 1
  //   } else {
  //     status = 2
  //   }
  //   this.$confirm('确定更改选中主题的状态吗？', '更改提示').then(async () => {
  //     await apiSetOnlineStatus({
  //       topic_id: id,
  //       online_status: status
  //     })
  //     this.getAllTopic()
  //   }).catch(() => {
  //   })
  // }

  // 编辑单行
  async editRow(id: any) {
    const res = await apiGetTopicDetailById(id)
    // console.log(res)
    let data: any = [res]
    // data.push({
    //   id: res.id,
    //   name: res.name,//中文名
    //   name_en: res.name_en,//英文名
    //   status: res.status,
    //   is_top: res.is_top,
    //   sort: res.sort,
    //   url: res.url,// 封面图
    //   note: res.note,//描述
    //   work_bench_type: res.work_bench_type,//所属模式
    //   from_origin_topic_name: res.from_origin_topic_name,
    //   models: res.models,
    //   models_name: res.models_name
    // })
    await this.$router.push({
      name: 'new_topic',
      params: {
        data
      }
    })
  }

//去编辑页，并把选中的主题传给编辑页
  goEdit() {
    if (this.multipleSelection.length === 0) {
      this.$message.error("请选中您想编辑的主题！")
      return
    }
    let status = true
    this.multipleSelection.forEach((item: any) => {
      if (item.online_status == 1) return status = false
    })
    if (!status) {
      return commonAlert('您选中的含有已发布的主题，不能编辑！', '编辑提示')
    }
    const id = this.multipleSelection.map((item: any) => item.id)
    apiGetTopicDetailByIds({ids: id}).then((res: any) => {
      this.$router.push({
        name: 'new_topic',
        params: {
          data: res
        }
      })
    })
  }

// 切换标签的时候
  handleClick(tab: any) {
    switch (tab.index) {
      case '0':
        this.form.work_bench_type = 1;
        break;
      case '1':
        this.form.work_bench_type = 2;
        break;
      case '2':
        this.form.work_bench_type = 3;
        break;
    }
    
    this.getAllTopic();
  }

  rowStyle() {
    return "text-align:center";
  }

// 单个删除
  deleteRow(id: any) {
    this.$confirm('确定删除选中的主题吗?', '删除提示').then(async () => {
      await apiDelSingleTopic({id})
      await this.getAllTopic()
      this.$message({
        type: 'success',
        message: '删除成功!'
      });
    }).catch(() => {
    })
  }

// 批量删除
  async delSelectTopic() {
    if (this.multipleSelection.length === 0) {
      return this.$message.error("请选中您想删除的主题！")
    }
    let status = true
    this.multipleSelection.forEach((item: any) => {
      if (item.online_status == 1) return status = false
    })
    if (!status) {
      return commonAlert('您选中的含有已发布的主题，不能删除！', '失败提示')
    }
    await this.$confirm(`确定删除选中的${this.multipleSelection.length}个主题吗？`, '删除提示').then(async () => {
      await apiDelSelectTopic({
        ids: this.multipleSelection.map((item: any) => item.id)
      })
      this.$message({
        type: 'success',
        message: '删除成功!'
      });
    }).catch(() => {
      this.$message({
        message: '已取消删除'
      });
    });
    await this.getAllTopic()
  }

// 获取主题
  getAllTopic() {
    this.pager.request({
      callback: apiGetAllTopic,
      params: {
        ...this.form
      }
    }).then((res: any) => {
      this.tableData = res.lists
    })
  }

// 选中的时候的状态
  handleSelectionChange(val: any) {
    this.multipleSelection = val;
  }

// 状态格式化
  statusFormat(row: any) {
    switch (row.online_status) {
      case 0:
        return '未上线';
        break
      case 1:
        return '已上线';
        break
      case 2:
        return '已下线';
        break
    }
  }

// 获取主题下的素材
  goMaterial(row: any) {
    let status: any = null
    if (row.row.online_status == 1) {
      status = true
    } else {
      status = false
    }
    this.$router.push({
      name: 'material_manage',
      query: {
        topicId: row.row.id,
        status
      }
    })
  }

  // 创建人筛选
  filterMethodCreateName(value: any, row: any) {
    return row.create_name == value;
  }

  // 状态筛选
  filterMethodOnlineStatus(value: any, row: any) {
    return row.online_status == value;
  }

// 最后修改人筛选
  filterMethodLastName(value: any, row: any) {
    return row.last_name == value;
  }

  created() {
    this.getAllTopic()
  }
}
